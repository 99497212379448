import axios from 'axios'
import { Message } from 'element-ui'

axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'
axios.defaults.withCredentials = true
// 创建axios实例
const service = axios.create({
    // axios中请求配置有baseURL选项，表示请求URL公共部分
    // baseURL: '/api',
    // baseURL: 'https://dev.daliangqing.com/api/',
    baseURL: 'https://v.daliangqing.com/api',
    // 超时
    timeout: 30000
})
// request拦截器
service.interceptors.request.use(config => {
    // get请求映射params参数
    // config.headers.token = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJhcHBUeXBlIjoiQklHX01BUCIsImlwIjoiMTIwLjI3LjI0My4zMyIsInVzZXJBZ2VudCI6Ik1vemlsbGEvNS4wIChXaW5kb3dzIE5UIDEwLjA7IFdpbjY0OyB4NjQpIEFwcGxlV2ViS2l0LzUzNy4zNiAoS0hUTUwsIGxpa2UgR2Vja28pIENocm9tZS8xMjguMC4wLjAgU2FmYXJpLzUzNy4zNiIsImV4cCI6MTcyOTMyODAwMCwidXNlcklkIjoiNzY3Y2QxZmVhYTRkODQ3NmRmZDc4ZDJkZWNmNDcxNGYifQ.QOOxDOe-rmHAgxTbTqA2VhgbY0GeC1PRLuHQTrS1hYo'
    if (config.method === 'get' && config.params) {
        let url = config.url + '?';
        for (const propName of Object.keys(config.params)) {
            const value = config.params[propName];
            var part = encodeURIComponent(propName) + "=";
            if (value !== null && typeof (value) !== "undefined") {
                if (typeof value === 'object') {
                    for (const key of Object.keys(value)) {
                        let params = propName + '[' + key + ']';
                        var subPart = encodeURIComponent(params) + "=";
                        url += subPart + encodeURIComponent(value[key]) + "&";
                    }
                } else {
                    url += part + encodeURIComponent(value) + "&";
                }
            }
        }
        url = url.slice(0, -1);
        config.params = {};
        config.url = url;

    }
    return config
}, error => {
    lodaing = false
    console.log(error)
    Promise.reject(error)
})

// 响应拦截器
service.interceptors.response.use(res => {
    // console.groupCollapsed('%c' + res.config.method.toUpperCase() + '%c ' + res.request.responseURL, 'background:#FF6958;color:white', 'color:#000')
    // console.log(res.config.data ? JSON.parse(res.config.data) : '<null>')
    // console.log(res.data ? res.data : '<null>')
    // console.groupEnd()

    // 未设置状态码则默认成功状态
    const code = res.data.code || 'OK';
    // 获取错误信息
    if (code !== "OK") {
        // 如果没有登录或者没有权限
        if (code === 'NO_AUTH' || code === 'FORBIDDEN') {
            window.localStorage.clear()
            window.sessionStorage.clear()
            Message.error('没有权限登录,请联系管理员')
            window.location.href = "https://v.daliangqing.com"
        } else { // 其他错误报出错误信息
            Message.error(res.data.message)
            return res.data
        }
    } else {
        return res.data
    }
},
    error => {
        console.log('err' + error)
        let {
            message
        } = error;
        if (message == "Network Error") {
            message = "后端接口连接异常";
        } else if (message.includes("timeout")) {
            message = "系统接口请求超时";
        } else if (message.includes("Request failed with status code")) {
            message = "系统接口" + message.substr(message.length - 3) + "异常";
        }
        Message({
            message: message,
            type: 'error',
            duration: 5 * 1000
        })
        return Promise.reject(error)
    }
)

export default service
